import React from "react";
import Header from "../components/header";
import { graphql } from "gatsby";
import "../styles/styles.css";
import ReactHtmlParser from "react-html-parser";
// import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
// import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

// export function search(array, key, prop) {
//   // Optional, but fallback to key['name'] if not selected
//   prop = typeof prop === "undefined" ? "name" : prop;

//   for (var i = 0; i < array.length; i++) {
//     if (array[i][prop] === key) {
//       return array[i];
//     }
//   }
// }
// const month_en = [
//   "JAN",
//   "FEB",
//   "MAA",
//   "APR",
//   "MEI",
//   "JUN",
//   "JUL",
//   "AUG",
//   "SEP",
//   "OKT",
//   "NOV",
//   "DEC",
// ];
// const month_nl = [
//   "JAN",
//   "FEB",
//   "MAA",
//   "APR",
//   "MEI",
//   "JUN",
//   "JUL",
//   "AUG",
//   "SEP",
//   "OKT",
//   "NOV",
//   "DEC",
// ];
const month_fr = [
  "JAN",
  "FEV",
  "MAR",
  "AVR",
  "MAI",
  "JUIN",
  "JUIL",
  "AUÔT",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];
const TourEnTickets = ({ data, pageContext }) => {
  const compare = (a, b) => {
    let date1_txt = a.node.data.split(", ")[0].split("/");
    let date1 = new Date(date1_txt[2], date1_txt[1] - 1, date1_txt[0]);

    let date2_txt = b.node.data.split(", ")[0].split("/");
    let date2 = new Date(date2_txt[2], date2_txt[1] - 1, date2_txt[0]);

    if (date1 < date2) return -1;
    if (date1 > date2) return 1;
    return 0;
  };
  //console.log(data)
  let futureItems = data.allMysqlTourEnTickets.edges.filter((item) => {
    let dates = item.node.data.split(", ").reverse();
    var today = new Date();
    today.setDate(today.getDate() - 7);
    const dateParts = dates[0].split("/");
    var founddate = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
    return founddate >= today;
  });
  let orderedItems = futureItems.sort(compare);

  let groupedDateItems = orderedItems.map((item, index) => {
    let itemData = item.node.data.split(", ");
    let groupedDataArray = [];
    let groupIndex = -1;
    let monthYearCheck = "";
    let groupString = "";
    for (let date of itemData) {
      const dateParts = date.split("/");
      if (monthYearCheck !== dateParts[1] - 1 + "-" + dateParts[2]) {
        groupIndex++;
        groupString = date;
        groupedDataArray.push(groupString);
        monthYearCheck = dateParts[1] - 1 + "-" + dateParts[2];
      } else {
        groupString += ", " + date;
        groupedDataArray[groupIndex] = groupString;
      }
    }
    let groupedItem = item;
    groupedItem.data = groupedDataArray;
    return groupedItem;
  });
  const getLink = (link, info) => {
    if (link) {
      return (
        <a href={link} target="_blank" rel="noopener noreferrer">
          Tickets
        </a>
      );
    } else {
      if (info === 0) {
        return <span>-</span>;
      } else {
        return <span>Plus d'infos plus tard</span>;
      }
    }
  };
  return (
    <div>
      <Header back="true" backLink="/fr" taal={pageContext.langKey} />
      <div>
        <h1>Tour en Tickets</h1>
        <div className="responsiveTickets">
          {groupedDateItems.map((item, index) => {
            return (
              <div key={item.node.id}>
                <p>{item.node.title_fr}</p>
                <p>
                  {item.data.map((dateGroup, index) => {
                    let data = dateGroup.split(", ");
                    let dataString = "";
                    let month = "";
                    let year = "";
                    let first = true;
                    for (let date of data) {
                      if (first) {
                        first = false;
                      } else {
                        dataString += ", ";
                      }
                      const splittedDate = date.split("/");
                      dataString += splittedDate[0];
                      month = splittedDate[1] - 1;
                      year = splittedDate[2];
                    }
                    dataString += " " + month_fr[month] + " " + year + "<br />";
                    return ReactHtmlParser(dataString);
                  })}
                </p>
                <p>{item.node.plaats_fr}</p>
                <p>{getLink(item.node.link, item.node.meer_info_volgt)}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default TourEnTickets;
export const query = graphql`
  query {
    allMysqlTourEnTickets {
      edges {
        node {
          id
          plaats_fr
          voorstellingen_id
          link
          data
          meer_info_volgt
          title_fr
        }
      }
    }
  }
`;
